/* eslint-disable no-new */
/* eslint-disable func-names */
function toggleMobileMenu() {
    document
      .getElementById("toggleMobileMenu").addEventListener("click", function() {
        document.querySelector('body').classList.toggle("menu-open");
        document.getElementById("main-container").classList.toggle("view-disabled");
        document.getElementById("footer-container").classList.toggle("view-disabled");
        document.getElementById("menu-container").classList.toggle("view-disabled");
        document.querySelectorAll("div[id^='mobile-second-level']").forEach(ele => {
            ele.classList.add("view-disabled");
        });
        document.getElementById("mobile-top-level").classList.remove("view-disabled");
      });
  }
  
  function toggleSearchBar() {
    document.getElementById("mainSearchButton").addEventListener("click", function() {
      document.getElementById("mainSearchBar").classList.toggle("view-disabled");
    });
  }
  
  function toggleMobileNavButtons() {
    document.querySelectorAll("#back-btn").forEach(element => {
      element.addEventListener("click", function() { 
        document.querySelectorAll("div[id^='mobile-second-level']").forEach(ele => {
          ele.classList.add("view-disabled");
        });
        document.getElementById("mobile-top-level").classList.toggle("view-disabled");
        });  
    });
  
    document.getElementById("syvari-btn").addEventListener("click", function() { 
      document.getElementById("mobile-second-level-syvarit").classList.toggle("view-disabled");
      document.getElementById("mobile-top-level").classList.toggle("view-disabled");
    });
    
    document.getElementById("vaikkarit-btn").addEventListener("click", function() { 
      document.getElementById("mobile-second-level-vaikkarit").classList.toggle("view-disabled");
      document.getElementById("mobile-top-level").classList.toggle("view-disabled");
    });
    
    document.getElementById("tietoa-btn").addEventListener("click", function() { 
      document.getElementById("mobile-second-level-tietoa").classList.toggle("view-disabled");
      document.getElementById("mobile-top-level").classList.toggle("view-disabled");
    });
  }

  // Käytin tähän aikaa, vaikkei olis tarvinnu laittaa kuin action="/" form tägiin :)
  // function searchFunction() {
  //   document.querySelectorAll("#searchForm").forEach(form => {
  //     form.addEventListener('submit', event => {
  //       event.preventDefault();
  //       const url = `/?s=${event.target[0].value}`;
  //       window.location = url;
  //     });
  //   })
  // }

  window.addEventListener("resize", function(){
    if(window.innerWidth >= 906) {
      document.getElementById("main-container").classList.remove('view-disabled');
      document.getElementById("footer-container").classList.remove('view-disabled');
      document.getElementById("menu-container").classList.add('view-disabled');
    }  
  });

  document.addEventListener("DOMContentLoaded", function() {
    toggleMobileMenu();
    toggleSearchBar();
    toggleMobileNavButtons();
    // searchFunction();
  });